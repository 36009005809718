export const config = {
    apiKey: "AIzaSyBhr2YExOE85ZKHgVnOyk1_NQdEjsBK2Ts",
    authDomain: "swtoon-67940.firebaseapp.com",
    databaseURL: "https://swtoon-67940.firebaseio.com",
    projectId: "swtoon-67940",
    storageBucket: "swtoon-67940.appspot.com",
    messagingSenderId: "307432703381"
};

export const LogRocketID = '';
export const gaID = '';